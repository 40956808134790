
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as calendarmGrk3Qblb5Meta } from "F:/webroot/diary.ilovearomagrace.com/pages/calendar.vue?macro=true";
import { default as callbackr8rgtAfWBsMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/callback.vue?macro=true";
import { default as diariesv4vl73lnLLMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/diaries.vue?macro=true";
import { default as _91id_931YeXHVUzORMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/diary/[id].vue?macro=true";
import { default as erroriUVxHqMKqTMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/error.vue?macro=true";
import { default as loginXnRq77lWU8Meta } from "F:/webroot/diary.ilovearomagrace.com/pages/login.vue?macro=true";
import { default as logoutySkB1IEoNxMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/logout.vue?macro=true";
import { default as termspvecRVclDmMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/terms.vue?macro=true";
import { default as user_base_oilsbzPFiPFdC5Meta } from "F:/webroot/diary.ilovearomagrace.com/pages/user_base_oils.vue?macro=true";
import { default as user_oilsw7wHrcq39GMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/user_oils.vue?macro=true";
import { default as user_toolsxX4Rui2cj1Meta } from "F:/webroot/diary.ilovearomagrace.com/pages/user_tools.vue?macro=true";
import { default as waiting_approve0x7qjJLbfAMeta } from "F:/webroot/diary.ilovearomagrace.com/pages/waiting_approve.vue?macro=true";
export default [
  {
    name: "calendar",
    path: "/calendar",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/calendar.vue")
  },
  {
    name: "callback",
    path: "/callback",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/callback.vue")
  },
  {
    name: "diaries",
    path: "/diaries",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/diaries.vue")
  },
  {
    name: "diary-id",
    path: "/diary/:id()",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/diary/[id].vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/error.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginXnRq77lWU8Meta || {},
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/login.vue")
  },
  {
    name: "logout",
    path: "/logout",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/logout.vue")
  },
  {
    name: "terms",
    path: "/terms",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/terms.vue")
  },
  {
    name: "user_base_oils",
    path: "/user_base_oils",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/user_base_oils.vue")
  },
  {
    name: "user_oils",
    path: "/user_oils",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/user_oils.vue")
  },
  {
    name: "user_tools",
    path: "/user_tools",
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/user_tools.vue")
  },
  {
    name: "waiting_approve",
    path: "/waiting_approve",
    meta: waiting_approve0x7qjJLbfAMeta || {},
    component: () => import("F:/webroot/diary.ilovearomagrace.com/pages/waiting_approve.vue")
  }
]
import payload_plugin_dHWDzdkIDW from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_XRqXLjYgna from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_jLlloYqGiz from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_fgYGHnSKmv from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_0sxpytDjBz from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_UoNraxl3sM from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import chunk_reload_client_XEpowW5HtF from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "F:/webroot/diary.ilovearomagrace.com/.nuxt/components.plugin.mjs";
import prefetch_client_r0kAGnVPdk from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@22.10.10_db0@0.2.1_ioredis@5.4.2_magicast@0.3.5_qy5utlq67x3zkyabdp7nzs3lje/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "F:/webroot/diary.ilovearomagrace.com/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "F:/webroot/diary.ilovearomagrace.com/.nuxt/element-plus-injection.plugin.mjs";
import plugin_Iq7GnV1vLj from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/dayjs-nuxt@2.1.11_magicast@0.3.5_rollup@4.31.0/node_modules/dayjs-nuxt/dist/runtime/plugin.js";
import element_plus_HUzmwi0JYR from "F:/webroot/diary.ilovearomagrace.com/plugins/element-plus.ts";
import plugin_SL3zYW8u70 from "F:/webroot/diary.ilovearomagrace.com/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescri_dwrrpfuilsuioceyijreslliqe/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_XRqXLjYgna,
  unhead_jLlloYqGiz,
  router_fgYGHnSKmv,
  payload_client_0sxpytDjBz,
  navigation_repaint_client_UoNraxl3sM,
  chunk_reload_client_XEpowW5HtF,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_r0kAGnVPdk,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_Iq7GnV1vLj,
  element_plus_HUzmwi0JYR,
  plugin_SL3zYW8u70
]